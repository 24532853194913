import { FC } from "react";

const Contact: FC = () => {
  return (
    <div className="px-4 md:px-8 lg:px-16 text-center flex flex-col justify-center items-center">
      <div className="flex flex-col justify-center mt-8">
        <h1 className="text-4xl">Say Hello!</h1>
        <p className="mt-8 mb-4">You can reach us at</p>
        <div className="flex flex-col gap-y-8">
          <a
            href="mailto:littlefills@gmail.com"
            className="text-blue-500 underline flex items-center gap-4"
            target="_blank"
            rel="noreferrer"
          >
            <img src={"/email_white.png"} alt="email" className="w-12 h-12" />
            littlefills@gmail.com
          </a>
          <a
            className="flex items-center gap-4"
            href="https://www.instagram.com/littlefillsband/"
            target="_blank"
            rel="noreferrer"
          >
            <img src={"/instagram.png"} alt="tiktok" className="w-12 h-12" />
            @littlefillsband
          </a>
          <a
            className="flex items-center gap-4"
            href="https://www.tiktok.com/@littlefills"
            target="_blank"
            rel="noreferrer"
          >
            <img src={"/tiktok.png"} alt="tiktok" className="w-12 h-12" />
            @littlefills
          </a>
        </div>
      </div>
    </div>
  );
};

export default Contact;
