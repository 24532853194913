import { FC } from "react";
import { Spotify } from "../components/Spotify";
import { art } from "../constants/ascii";
import { Youtube } from "../components/Youtube";

const Home: FC = () => {
  return (
    <div className="flex-col text-center justify-center items-center overflow-hidden mb-10">
      <pre
        className={`text-xs md:text-xs text-nowrap scroll-text`}
        style={{
          fontFamily: "monospace",
          zIndex: -1,
          pointerEvents: "none",
          position: "relative",
        }}
      >
        {art}
      </pre>
      <div className="px-4 md:px-8 lg:px-16">
        <div className="flex flex-col justify-center mt-8 text-4xl gap-4">
          <h1>New Album</h1>
          <span className="text-6xl animate-color-change">
            {" "}
            OCCULT TOURIST TRAP{" "}
          </span>
          <h1>Coming October 25!</h1>
        </div>
        <div className="flex flex-col md:flex-row mt-12 gap-8">
          <div className="w-full flex flex-col">
            <span className="my-4">
              Check out the new music video for BEYOND REPAIR!
            </span>
            <Youtube />
          </div>
          <div className="w-full flex flex-col">
            <span className="my-4">Listen to us on Spotify!</span>
            <Spotify />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
