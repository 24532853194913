import { FC, PropsWithChildren, useState } from "react";
import { Button, Window, WindowHeader, Toolbar, ScrollView } from "react95";
import styled, { keyframes, css } from "styled-components";
import original from "react95/dist/themes/original";

const StyledWindowHeader = styled(WindowHeader)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* background-color: #071284; */
`;

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const CloseIcon2 = styled.span<{ isSpinning?: boolean }>`
  display: inline-flex; /* Use flexbox to center */
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  margin-left: -1px;
  margin-top: -1px;
  font-size: 16px; /* Adjust the font size of the emoji */

  ${({ isSpinning }) =>
    isSpinning &&
    css`
      animation: ${spin} 1s linear infinite;
    `}
`;

interface WindowWrapperProps extends PropsWithChildren {
  title: string;
}

export const WindowWrapper: FC<WindowWrapperProps> = ({ children, title }) => {
  const [isSpinning, setIsSpinning] = useState(false);

  const handleClick = () => {
    setIsSpinning(!isSpinning);
  };

  return (
    <Window
      style={{
        width: "100%",
        margin: "auto",
        zIndex: 98,
      }}
    >
      <StyledWindowHeader theme={original}>
        <span className="text-white">{title}</span>
        <Button onClick={handleClick}>
          <CloseIcon2 isSpinning={isSpinning} theme={original}>
            ☠️
          </CloseIcon2>
        </Button>
      </StyledWindowHeader>
      <Toolbar>
        <Button variant="menu" size="sm" disabled>
          File
        </Button>
        <Button variant="menu" size="sm" disabled>
          Edit
        </Button>
      </Toolbar>
      <ScrollView>
        <div className="flex justify-center my-2">{children}</div>
      </ScrollView>
    </Window>
  );
};
