import { WindowWrapper } from "./WindowWrapper";

export const Youtube = () => {
  return (
    <WindowWrapper title="youtube.exe">
      <iframe
        src="https://www.youtube.com/embed/MhvRHe2sSP4?si=2DwsddJUBkJcJMWg"
        width="95%"
        height="352"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        loading="lazy"
        title="youtube"
      />
    </WindowWrapper>
  );
};
