import { styleReset } from "react95";
import { createGlobalStyle, ThemeProvider } from "styled-components";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

/* Pick a theme of your choice */
import matrix from "react95/dist/themes/matrix";

import NavBar from "./components/NavBar";
import Home from "./pages/Home";
import Contact from "./pages/Contact";

const GlobalStyles = createGlobalStyle`
  ${styleReset}
  @font-face {
    font-family: 'ms_sans_serif';
    src: url('/fonts/ms_sans_serif.woff') format('woff');
    font-weight: 400;
    font-style: normal
  }
  @font-face {
    font-family: 'ms_sans_serif';
    src: url('/fonts/ms_sans_serif_bold.woff') format('woff');
    font-weight: bold;
    font-style: normal
  }
  body, input, select, textarea {
    font-family: 'ms_sans_serif';
    background-color: rgb(0, 0, 0);
    color: #ffffff /* Set the background color here */
  }

  .content {
    padding-top: 60px; /* Adjust based on your NavBar height */
  }
`;

const App = () => (
  <Router>
    <GlobalStyles />
    <ThemeProvider theme={matrix}>
      <NavBar />
      <div className="content" />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
    </ThemeProvider>
  </Router>
);

export default App;
