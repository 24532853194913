import { WindowWrapper } from "./WindowWrapper";

export const Spotify = () => {
  return (
    <WindowWrapper title="spotify.exe">
      <iframe
        src="https://open.spotify.com/embed/artist/7rk37mVhDIvicgKeLGekvU?utm_source=generator"
        width="95%"
        height="352"
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        loading="lazy"
        title="spotify"
      />
    </WindowWrapper>
  );
};
