import { AppBar, Button, MenuList, MenuListItem, Toolbar } from "react95";
import { FC, useState } from "react";

import { Link, useLocation } from "react-router-dom";

const NavBar: FC = () => {
  const [open, setOpen] = useState(false);

  const pathName = useLocation().pathname;

  return (
    <AppBar style={{ zIndex: 99 }}>
      <Toolbar style={{ justifyContent: "space-between" }}>
        <div className="flex gap-4 md:gap-6">
          <Button
            onClick={() => setOpen(!open)}
            active={open}
            style={{ fontWeight: "bold" }}
          >
            Start
          </Button>
          {open && (
            <MenuList
              style={{
                position: "absolute",
                left: "0",
                top: "100%",
                zIndex: 10,
              }}
              onClick={() => setOpen(false)}
            >
              <MenuListItem>
                <Link
                  to="/"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  <div className="flex gap-8">
                    <span role="img" aria-label="🏠">
                      🏠
                    </span>
                    <span>Home</span>
                  </div>
                </Link>
              </MenuListItem>
              <MenuListItem>
                <Link
                  to="/contact"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  <div className="flex gap-8">
                    <span role="img" aria-label="🏠">
                      📡
                    </span>
                    <span>Contact</span>
                  </div>
                </Link>
              </MenuListItem>
              <MenuListItem disabled>
                <div className="flex gap-8">
                  <span role="img" aria-label="🏠">
                    🛗
                  </span>
                  <span>About</span>
                </div>
              </MenuListItem>
            </MenuList>
          )}
          <a
            className="flex items-center gap-4"
            href="https://www.instagram.com/littlefillsband/"
            target="_blank"
            rel="noreferrer"
          >
            <img src={"/instagram.png"} alt="tiktok" className="w-8 h-8" />
          </a>
          <a
            className="flex items-center gap-4"
            href="https://www.tiktok.com/@littlefills"
            target="_blank"
            rel="noreferrer"
          >
            <img src={"/tiktok.png"} alt="tiktok" className="w-8 h-8" />
          </a>
          <a
            className="flex items-center gap-4"
            href="https://open.spotify.com/artist/7rk37mVhDIvicgKeLGekvU"
            target="_blank"
            rel="noreferrer"
          >
            <img src={"/spotify2.png"} alt="tiktok" className="w-8 h-8" />
          </a>
        </div>
        <Link
          to={pathName === "/" ? "/contact" : "/"}
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <Button>
            <div className="flex gap-4">
              <span role="img" aria-label="🏠">
                {pathName === "/" ? "📡" : "🏠"}
              </span>
              <span>{pathName === "/" ? "Contact" : "Home"}</span>
            </div>
          </Button>
        </Link>
      </Toolbar>
    </AppBar>
  );
};

export default NavBar;
